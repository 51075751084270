@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
* {
  font-size: 16px;
}

a {
  text-decoration: none;
}

.MuiButton-containedPrimary {
  color: #ffffff !important;
}

.fc-right .fc-prev-button,
.fc-right .fc-next-button {
  background-color: #4caf50;
  background-image: none;
  border: none;
}

.fc-button-primary {
  border: none;
}

.fc-event,
.fc-event-dot {
  background-color: #d86437 !important;
  border: none !important;
}
